<template>
  <div class="kua-kua-wrap">
    <div class="flex">
      <div class="flex-left">
        <h3 class="result-title">Input</h3>
        <div class="textarea-box" :class="{ 'error-textarea-box': emptyError }">
          <div class="icon-close" @click="handleClear" v-if="inputText">
            <img :src="iconClose" />
          </div>
          <a-textarea v-model:value="inputText"
            placeholder="Enter the keywords of your comments and let our tool sweeten your words. E.g. Love this product"
            :maxlength="300" class="textarea-content" @change="handleInputContent" />
          <div class="count">{{ inputText.length || 0 }} / 300</div>
        </div>
      </div>
      <div class="flex-right">

        <h3 class="result-title">Output</h3>
        <div class="content-box">

          <div class="bubble-flex-box">
            <div class="bubble-content-box"></div>
            <div class="icon-loading" v-if="isLoading">
              <div class="icon-circular-box">
                <DynamicEllipsis radius="12px" color="#C4ADFF" activeColor="#C4ADFF" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="btn-container" :style="{ 'margin-top': minClient ? '20px' : '54px' }" v-authenticate:SUGAR_COMMENTS>
      <custom-btn :customStyle="customStyle" @click="(e) => handleGenerate(e, item)" :showPrefixIcon="true"
        activeColor="linear-gradient(289deg, #7646ff 18.5%, #9c7aff 99.95%)" :disabled="isLoading">
        <p style="color:#fff !important;">AI Generate</p>
      </custom-btn>
    </div>

    <no-credit-modal v-if="hasCredit === false" @close="handleNoCreditModalClose" />
  </div>
</template>
<script setup>
import axios from "axios";
import iconClose from '@/assets/common-icons/icon_close.svg'
import CustomBtn from "@/components/boolv-ui/custom-btn/index.vue";
import { getSmartResponseGenerator } from '@/api/models/kuakua.js'
import { ref, reactive, watchEffect, watch, onUnmounted, onMounted } from "vue";
import DynamicEllipsis from "@/components/dynamic-ellipsis.vue";
import noCreditModal from "@/components/boolv-ui/custom-modal/custom-no-credit-modal.vue";
import { MessageLoading, destroy, MessageSuccess, MessageError } from "@/components/boolv-ui/Message/index.js";
import { eventTracking } from "@/utils/eventTracking.js";
import { isOnlyEnglish } from '@/utils/index.js'
import { useSideStore, useViewStore } from '@/store/index.js'
import { storeToRefs } from 'pinia'
const sideStore = useSideStore()
const viewStore = useViewStore()
const { minClient } = storeToRefs(sideStore)

const inputText = ref('')
const customStyle = reactive({
  width: '270px',
  height: '64px',
  background: 'linear-gradient(289deg, #632CFF 8.11%, #8E68FF 99.95%)',
  'border-radius': '60px'
})

const hasCredit = ref(true)
const outputList = ref([])
const isLoading = ref(false)
const isGenerate = ref(false)
const theLastCircular = ref(null)
const emptyError = ref(false)
const cancelToken = axios.CancelToken.source()
const source = ref(cancelToken);
const currentTexts = ref(null)
const timer = ref(null)
const handleClear = () => {
  inputText.value = ''
  cleanDom()
  cleanOutPut()
}


onMounted(() => {
  // viewStore.setMinViewWidth('1400px')
})
const clearTask = (text = 'Error') => {
  isLoading.value = false
  destroy()
  MessageError(text)
  setTimeout(() => {
    destroy()
  }, 1000)
}

const handleNoCreditModalClose = () => {
  hasCredit.value = true
}

const cleanDom = () => {
  let contentBox = document.getElementsByClassName('bubble-content-box')[0]
  var pObjs = contentBox.childNodes;
  for (var i = pObjs.length - 1; i >= 0; i--) { // 一定要倒序，正序是删不干净的
    contentBox.removeChild(pObjs[i]);
  }
}

const cleanOutPut = () => {
  isGenerate.value = true
  outputList.value = []
  currentTexts.value = []
  clearTimeout(timer.value)
  timer.value = null
}

const handleGenerate = async () => {
  // await checkCurrentResource()

  if (!inputText.value) {
    emptyError.value = true
    clearTask('Input cannot be empty!')
    emptyError.value = true
    return
  }

  if (!isOnlyEnglish(inputText.value)) {
    emptyError.value = true
    clearTask('Only allow English characters and numbers for text input.')
    return
  }

  theLastCircular.value = null
  isGenerate.value = true
  outputList.value = []
  currentTexts.value = []
  clearTimeout(timer.value)
  timer.value = null
  cleanDom()
  let params = {
    text: inputText.value
  }
  isLoading.value = true
  sideStore.showGlobalMask = true;
  // MessageLoading("Processing, it will take a while", 0, "", null, false, cancelRequest);
  getSmartResponseGenerator(params, {
    cancelToken: source.value.token,
  })
    .then(res => {
      isLoading.value = false
      const { code, data } = res
      const { texts } = data
      currentTexts.value = texts
      sideStore.showGlobalMask = false
      eventTracking("booltool_page_download", { tool_name: "sugar_comment", is_batch: false, is_success: true })
      if (!texts || !texts.length) {
        clearTask('No answer')
        return
      }
      destroy()
      MessageSuccess("Processing completed");
      setTimeout(() => {
        destroy()
      }, 2000)

    }).catch(() => {
      sideStore.showGlobalMask = false
      isLoading.value = false
      destroy()
      clearTask(null)
    })

}
function rightClickCopy (event) {
  // event.preventDefault();
  let selection = window.getSelection();
  let range = document.createRange();
  range.selectNodeContents(this);
  selection.removeAllRanges();
  selection.addRange(range);
  // MessageSuccess('Copied to clipboard!');
}
watch(currentTexts, () => {
  if (currentTexts.value) {
    let contentBox = document.getElementsByClassName('bubble-content-box')[0]

    currentTexts.value.forEach((text, index) => {
      let container = document.createElement("div");
      let bubble = document.createElement("div");
      // bubble.setAttribute('style', `line-height: 32px; ${index % 2 == 0 ? 'background: #d4cbff' : 'background: #eee8ff'}; transform: scale(0); height: auto; transition: transform 0.5s ease-in-out; border-radius: 20px;  position: relative`)
      bubble.setAttribute('style', `display: flex; font-size: 16px; word-break:break-all;word-wrap: break-word; align-items: center; justify-content: center;line-height: 32px; text-align: left; height: auto; min-height:92px ; width: fit-content; background:#F0EBFF;  border-radius: 20px;padding: 14px 28px; transition: transform 0.5s ease-in-out;`)
      bubble.innerText = text
      bubble.oncontextmenu = rightClickCopy;
      bubble.oncopy = () => {
        MessageSuccess('Copied to clipboard!');
      }
      let svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
      svg.setAttribute('viewBox', '0 0 19 23');
      svg.setAttribute('height', 23);
      svg.setAttribute('width', 19);
      svg.setAttribute('fill', 'none');
      svg.setAttribute('style', 'position: absolute; bottom: 0; right: -9px');
      var path = document.createElementNS('http://www.w3.org/2000/svg', 'path');
      path.setAttribute('fill', `#F0EBFF`);
      path.setAttribute('d', 'M19 23C9.37333 15.5405 10 0 10 0L0 15.5405C6.08 20.5135 15.2 22.5856 19 23Z');
      bubble.addEventListener('mouseenter', () => {
        bubble.setAttribute('style', `display: flex; font-size: 16px; word-break:break-all;word-wrap: break-word; align-items: center; justify-content: center;line-height: 32px; text-align: left; height: auto;  min-height:92px ; width: fit-content; background:#D9D1FF;  border-radius: 20px;padding: 14px 28px; transition: transform 0.5s ease-in-out;`)
        path.setAttribute('fill', `#D9D1FF`);
      });
      bubble.addEventListener('mouseleave', () => {
        bubble.setAttribute('style', `display: flex; font-size: 16px; word-break:break-all;word-wrap: break-word; align-items: center; justify-content: center;line-height: 32px; text-align: left; height: auto;  min-height:92px ; width: fit-content;background:#F0EBFF;  border-radius: 20px;padding: 14px 28px; transition: transform 0.5s ease-in-out;`)
        path.setAttribute('fill', `#F0EBFF`);
      });
      timer.value = setTimeout(() => {
        svg.appendChild(path);
        container.appendChild(bubble);
        container.appendChild(svg);
        contentBox.appendChild(container);
        container.style.transform = "scale(1)";
        container.style.transition = "transform 0.5s ease-in-out, top 0.5s ease-in-out";
        container.style.width = '526px';
        container.style.display = 'flex';
        container.style.justifyContent = 'end';
        // container.style.padding = '14px 28px';
        container.style.marginBottom = index === currentTexts.value.length - 1 ? '0px' : '27px';
      }, index * 500);
    });

  }
})


const handleInputContent = () => {
  emptyError.value = false
}


onUnmounted(() => {
  destroy()
  clearTimeout(timer.value)
  timer.value = null
})

</script>

<style lang="less" scoped>
.kua-kua-wrap {
  min-height: calc(100vh - 76px);
  min-width: 1400px;
  padding: 36px 32px 0px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;


  :global(.ant-input-textarea) {
    height: 100%;
  }

  :global(.ant-input-affix-wrapper) {
    height: 100%;
  }

  :global(.ant-input) {
    border: none;
  }

  .result-title {
    font-family: 'Inter', Arial;
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 26px;
    margin-bottom: 20px;
  }

}

.flex-left {
  margin-right: 64px;
}

.textarea-box {
  width: 616px;
  border: 1px solid #D0D0D0;
  height: 509px;
  padding: 38px 27.25px 26px 24px;
  border-radius: 6px;
  position: relative;


  :global(textarea.ant-input) {
    height: 100%;
    outline: none !important;
    resize: none;
  }

  :global(.ant-input) {
    outline: none;
    padding: 0;
  }

  input:focus,
  textarea:focus {
    outline: none;

  }

  textarea {
    padding-right: 40px;
    line-height: 32px;
    font-size: 16px;
  }
}

.error-textarea-box {
  border: 1px solid red;
}



.icon-close {
  position: absolute;
  cursor: pointer;
  right: 24px;
  z-index: 100;
  top: 24px;

  &:hover {
    background-color: #eee;
    border-radius: 4px;
  }
}

.count {
  position: absolute;
  right: 24px;
  z-index: 100;
  bottom: 24px;
  cursor: pointer;
  color: #8F959E;
}



.icon-circular {
  width: 12px;
  height: 12px;
  background-color: #DACCFF;
  border-radius: 10px;
}

.icon-circular-box {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;

}

.marginR6 {
  margin-right: 6px;
}

.icon-circular-active {
  background-color: #C4ADFF;
}

.content-box {
  border: 1px solid #D0D0D0;
  width: 616px;
  height: 509px;
  border-radius: 6px;
  padding: 37px 30px 30px 33px;
  display: flex;
  align-items: center;

  .bubble-flex-box {
    width: 100%;
    height: 445px;
    overflow: scroll;
  }

  .bubble-content-box {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.icon_bubble-box {
  width: 526px;
  min-height: 124px;
  border-radius: 20px;
}

.animation-box {
  background-color: #eee8ff;
  animation: animation_bubble 0.3s ease-in;
  position: absolute;
  bottom: 0;
  left: 0;
}


@keyframes animation_bubble {
  0% {
    scale: 0.5;
    bottom: 0;
    left: 124px;
  }

  100% {
    scale: 1;
    bottom: 0px;
    left: 0
  }
}

.icon_bubble {
  padding: 14px 28px;
  line-height: 32px;
  width: 100%;
}

.icon_bubble_first {
  width: 100%;
  min-height: 124px;

}

.icon_bubble_second {
  height: 100%;
  width: 100%;
}

.icon_bubble_three {
  height: 100%;
  width: 100%;
}

.marginB26 {
  margin-bottom: 26px;
}

.btn-container {
  // margin-top: 104px;

}

.flex-right {
  position: relative;
}

.icon-loading {
  width: 122px;
  height: 39px;
  position: absolute;
  right: 42px;
  bottom: 42px;
  background-image: url('../../../assets/common-icons/icon_loading.png');
  background-size: 100%;
  background-repeat: no-repeat;
}

textarea,
textarea.ant-input:hover,
textarea:focus {
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.flex-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}


::-webkit-scrollbar {
  width: 7px;
}

/* 滚动槽 */
::-webkit-scrollbar-track {
  // -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  height: 50px;
  background: rgba(31, 35, 41, 0.3);
  // -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(31, 35, 41, 0.3);
}

.btn-suffixIcon {
  width: 40px;
  height: 24px;
  margin-left: 12px;
}
</style>
