<template>
  <custom-modal :visible="innerVisible" :closable="false" :footer="null">
    <template #modal-title>
      <div class="modal-header flex items-center justify-between">
        <div class="flex items-center ">
          <img :src="iconWarning" class="pr-4" />
          {{ title || 'No credit, unable to upload'}}
        </div>

        <div class="close_icon-box">
          <img :src="close_icon" :style="{ width: '15.46px', height: '15.46px' }" @click="handleCloseModal" />
        </div>
      </div>
    </template>
    <template #modal-content>
      <p class="pt-2 pl-10"> {{ content || 'Credit has been used up, use it after subscribing.'}}</p>
    </template>
    <template #operate-button>
      <div class="flex justify-end pt-6">
        <custom-btn activeColor="linear-gradient(289deg, #7646ff 18.5%, #9c7aff 99.95%)" :customStyle="customStyle"
          :showPrefixIcon="false" @click="(e) => handleBuyCredit(e)">

          {{ okText || 'Buy credit'}}
        </custom-btn>
      </div>
    </template>
  </custom-modal>
</template>

<script setup>
import { reactive, watchEffect, ref, defineEmits, defineProps } from 'vue'
import iconWarning from '@/assets/common-icons/icon_warning_colorful.svg'
import CustomBtn from "@/components/boolv-ui/custom-btn/index.vue";
import close_icon from "@/assets/icon_close.svg";
import CustomModal from './custom-modal.vue'
import { useUserStore } from '@/store/user.js'
import { storeToRefs } from 'pinia'
import router from "@/router/index";
const userStore = useUserStore()
const { hasCredit } = storeToRefs(userStore)

const emits = defineEmits(['close'])

const props = defineProps({
  title: String,
  content: String,
  okText: String
})
const innerVisible = ref(false)
const customStyle = reactive({
  width: '91px',
  height: '32px',
  background: 'linear-gradient(289deg, #632CFF 8.11%, #8E68FF 99.95%)',
  'border-radius': '60px',
  'margin-top': '10px'
})

watchEffect(() => {
  if (!hasCredit.value) {
    innerVisible.value = true
  }
})

const handleCloseModal = () => {
  innerVisible.value = false
  emits('close')
}
const handleBuyCredit = () => {
  innerVisible.value = false
  router.push("/premium");
}
</script>

<style lang="less" scoped>
.close_icon-box {
  width: 24px;
  height: 24px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;

  &:hover {
    background-color: #eaeaea;
  }
}
</style>