<template>
  <div class="dynamic-ellipsis-container" :style="containerStyle">
    <div class="k-lineA-1" :style="ellipsisStyle" />
    <div class="k-lineA-2" :style="ellipsisStyle" />
    <div class="k-lineA-3" :style="ellipsisStyle" />
    <!-- <div class="k-lineA-4" :class="ellipsisStyle" />
    <div class="k-lineA-5" :class="ellipsisStyle" />
    <div class="k-lineA-6" :class="ellipsisStyle" /> -->
  </div>
</template>

<script setup>

import { ref, defineProps, computed } from 'vue'
const props = defineProps({
  radius: String,
  color: String,
  activeColor: String,
  display: String,
})

const containerStyle = computed(() => {
  //  display: props.display

  const style = {

  }

  const customStyle = {
    display: props.display,

  }

  return {
    ...style,
    ...customStyle
  }

})

const ellipsisStyle = computed(() => {
  const style = {
    display: 'inline-block',
    width: '4px',
    height: '4px',
    'border-radius': '4px',
    background: 'rgb(167, 164, 163)',
    'margin-right': '4px'
  }


  const customStyle = {
    width: props.radius,
    height: props.radius,
    'border-radius': props.radius,
    background: props.color,

  }

  return {
    ...style,
    ...customStyle
  }
})
</script>

<style lang="less">
.k-line {
  display: inline-block;
  width: 4px;
  height: 4px;
  border-radius: 4px;
  background: rgb(167, 164, 163);
  margin-right: 2px;
}

.dynamic-ellipsis-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.k-lineA-1 {
  animation: loading 1.5s 0s infinite;
}

.k-lineA-2 {
  animation: loading 1.5s 0.3s infinite;
}

.k-lineA-3 {
  animation: loading 1.5s 0.6s infinite;
}

.k-lineA-4 {
  animation: loading 1.5s 0.9s infinite;
}

.k-lineA-5 {
  animation: loading 1.5s 1.2s infinite;
}

.k-lineA-6 {
  animation: loading 1.5s 1.5s infinite;
}

@keyframes loading {
  0% {
    // transform: translateY(0);
    // transform: scale(1);
    opacity: 1;
  }

  30% {
    //  transform: translateY(2px);
    // transform: scale(1.1);
    opacity: 0.8;
  }


  50% {
    //  transform: translateY(4px);
    // transform: scale(1.2);
    opacity: 0.7;
  }


  70% {
    //  transform: translateY(2px);
    // transform: scale(1.3);
    opacity: 0.6;
  }

  85% {
    // transform: translateY(-2px);
    // transform: scale(1.4);
    opacity: 0.5;
  }

  100% {
    //  transform: translateY(0);
    // transform: scale(1.5);
    opacity: 0.3;
  }
}
</style>