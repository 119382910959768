

import { _post } from "@/utils/request.js";
const preApi = process.env.NODE_ENV !== "production" ? "/api" : process.env.VUE_APP_API;
const URL = {
  smartResponseGenerator: preApi + "/ai/smart_response_generator",

};

const getSmartResponseGenerator = async (data) => _post(URL.smartResponseGenerator, data);

export { getSmartResponseGenerator };